import React, { Suspense, useEffect, useState } from "react";
import AuthAPI from "./api/auth";
import Layout from "./components/layout/layout";
import { Loader } from "@lipitech/ec-ui";
import LipiEduAPI from "@lipitech/edu-client-sdk";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const LoginPage = React.lazy(() =>
  import("./components/login").then((module) => ({ default: module.LoginPage }))
);
const ForgotPasswordPage = React.lazy(() =>
  import("./components/login").then((module) => ({
    default: module.ForgotPasswordPage,
  }))
);
const OTPVerifyPage = React.lazy(() =>
  import("./components/login").then((module) => ({
    default: module.OTPVerifyPage,
  }))
);
const CreatePasswordPage = React.lazy(() =>
  import("./components/login").then((module) => ({
    default: module.CreatePasswordPage,
  }))
);
const ResetPasswordPage = React.lazy(() =>
  import("./components/login").then((module) => ({
    default: module.ResetPasswordPage,
  }))
);
const ByPassLoginPage = React.lazy(() =>
  import("./components/login/").then((module) => ({
    default: module.ByPassLoginPage,
  }))
);

const Dashboard = React.lazy(() =>
  import("./components/dashboard/dashboard").then((module) => ({
    default: module.Dashboard,
  }))
);

const Register = React.lazy(() =>
  import("./components/register/register").then((module) => ({
    default: module.Register,
  }))
);

const Logout = React.lazy(() => import("./components/logout/logout"));

const CourseSelectionPage = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.CourseSelectionPage,
  }))
);
const StudentTestBundleList = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentTestBundleList,
  }))
);
const StudentTestBundleDetails = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentTestBundleDetails,
  }))
);
const StudentTestBundleDetailsToSub = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentTestBundleDetailsToSub,
  }))
);
const StudentTest = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentTest,
  }))
);
const StudentTestInstructions = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentTestInstructions,
  }))
);
const StudentTestResult = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentTestResult,
  }))
);

const UserEduPro = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.UserEduPro,
  }))
);

// const UserNotifications = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.UserNotifications,
//   }))
// );
const StudentProfile = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentProfile,
  }))
);

const TicketList = React.lazy(() =>
  import("./components/support").then((module) => ({
    default: module.TicketList,
  }))
);
const ComplaintSection = React.lazy(() =>
  import("./components/support").then((module) => ({
    default: module.ComplaintSection,
  }))
);
const Replies = React.lazy(() =>
  import("./components/support").then((module) => ({ default: module.Replies }))
);

const ReportEducatorList = React.lazy(() =>
  import("./components/reports").then((module) => ({
    default: module.ReportEducatorList,
  }))
);
const ReportEducatorDetail = React.lazy(() =>
  import("./components/reports").then((module) => ({
    default: module.ReportEducatorDetail,
  }))
);
const ReportStudentList = React.lazy(() =>
  import("./components/reports").then((module) => ({
    default: module.ReportStudentList,
  }))
);
const ReportStudentDetail = React.lazy(() =>
  import("./components/reports").then((module) => ({
    default: module.ReportStudentDetail,
  }))
);
const ReportTestList = React.lazy(() =>
  import("./components/reports").then((module) => ({
    default: module.ReportTestList,
  }))
);
const ReportTestReports = React.lazy(() =>
  import("./components/reports").then((module) => ({
    default: module.ReportTestReports,
  }))
);
const ScholarshipOTPVerification = React.lazy(() =>
  import("./components/scholarship/otpverification").then((module) => ({
    default: module.ScholarshipOTPVerification,
  }))
);
const ScholarshipUserInfo = React.lazy(() =>
  import("./components/scholarship/personalInfo").then((module) => ({
    default: module.ScholarshipUserInfo,
  }))
);
const SchorarshipLogin = React.lazy(() =>
  import("./components/scholarship/login").then((module) => ({
    default: module.SchorarshipLogin,
  }))
);
const DSStudentAttemptTest = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.DSStudentAttemptTest,
  }))
);
const PaymentStatus = React.lazy(() =>
  import("./components/payment/PaymentStatus").then((module) => ({
    default: module.PaymentStatus,
  }))
);
const FreeResourceList = React.lazy(() =>
  import("./components/freeResource").then((module) => ({
    default: module.FreeResourceList,
  }))
);
const FreeResourceDetails = React.lazy(() =>
  import("./components/freeResource").then((module) => ({
    default: module.FreeResourceDetails,
  }))
);
const AssignedTestInterview = React.lazy(() =>
  import("./components/assigned-test-interview").then((module) => ({
    default: module.AssignedTestInterview,
  }))
);
const StudentProgress = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.StudentProgress,
  }))
);
const MyGoalsPage = React.lazy(() =>
  import("./components/userDashboard").then((module) => ({
    default: module.MyGoalsPage,
  }))
);



export const RenderRoute = ({ children, layout }: any) => {
  const { styles: siteStyles, title } = LipiEduAPI.getConfig();
  const styles = siteStyles || {};
  const [isLoading, setLoading] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate()
  const [selectedCourse, setSelectedCourse] = useState({});
  const onCourseSelect = (
    course: any,
    isCourseListEmpty?: boolean,
    isSelectFromDropdown?: boolean
  ) => {
    if (isCourseListEmpty || !course) {
      navigate({
        pathname: "/course/selection"
      })
    } else {
      setSelectedCourse(course);
      // if (isSelectFromDropdown) {
      //   handleRedirection();
      // }
    }
  };

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link && styles?.favicon) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (styles?.favicon) {
      link.href = LipiEduAPI.asset.getAssetUrl(styles.favicon);
    }
    document.title = title || "";
  }, []);
  useEffect(() => {
    if (!AuthAPI.isLoggedIn() && !AuthAPI.getCurrentUser()) {
      AuthAPI.setCurrentUser()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          const pathname = location.pathname
            ? "/login?url=" + location.pathname
            : "/login";
          navigate(
            pathname,
          );
          // navigate.push('/logout');
        });
    } else {
      setLoading(false);
    }
  }, []);
  if (layout) {
    return (
      <Layout onCourseSelect={onCourseSelect} selectedCourse={selectedCourse}>
        <Suspense
          fallback={
            <Loader
              imageUrl={LipiEduAPI.asset.getAssetUrl(styles.siteLoader)}
            />
          }
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { selectedCourse })
          )}
        </Suspense>
      </Layout>
    );
  }
  return (
    <Suspense
      fallback={
        <Loader imageUrl={LipiEduAPI.asset.getAssetUrl(styles.siteLoader)} />
      }
    >
      {children}
    </Suspense>
  );
};

export const routes: any = [
  {
    path: "/",
    children: [
      {
        path: "/",
        element: (
          <Navigate to={AuthAPI.getRedirectUrl(AuthAPI.getCurrentUser())} />
        ),
      },
      {
        path: "/login",
        element: (
          <RenderRoute layout={false}>
            <LoginPage />
          </RenderRoute>
        ),
      },
      {
        path: "/scholarship/otp-verify",
        element: (
          <RenderRoute layout={false}>
            <ScholarshipOTPVerification />
          </RenderRoute>
        ),
      },
      {
        path: "/scholarship/testdetail",
        element: (
          <RenderRoute layout={false}>
            <ScholarshipUserInfo />
          </RenderRoute>
        ),
      },
      {
        path: "/scholarship",
        element: (
          <RenderRoute layout={false}>
            <SchorarshipLogin />
          </RenderRoute>
        ),
      },
      {
        path: "/login",
        element: (
          <RenderRoute layout={false}>
            <LoginPage />
          </RenderRoute>
        ),
      },
      {
        path: "/forgot-password",
        element: (
          <RenderRoute layout={false}>
            <ForgotPasswordPage />
          </RenderRoute>
        ),
      },
      {
        path: "/otp-verify",
        element: (
          <RenderRoute layout={false}>
            <OTPVerifyPage />
          </RenderRoute>
        ),
      },
      {
        path: "/create-password",
        element: (
          <RenderRoute layout={false}>
            <CreatePasswordPage />
          </RenderRoute>
        ),
      },
      {
        path: "/reset-password/:userId/:token",
        element: (
          <RenderRoute layout={false}>
            <ResetPasswordPage />
          </RenderRoute>
        ),
      },
      {
        path: "/logout",
        element: (
          <RenderRoute layout={false}>
            <Logout />
          </RenderRoute>
        ),
      },
      {
        path: "/personal-detail",
        element: (
          <RenderRoute layout={false}>
            <Register />
          </RenderRoute>
        ),
      },
      {
        path: "/course/selection",
        element: (
          <RenderRoute layout={false}>
            <CourseSelectionPage />
          </RenderRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <RenderRoute layout={true}>
            <Dashboard />
          </RenderRoute>
        ),
      },
      {
        path: "/my-profile",
        element: (
          <RenderRoute layout={true}>
            <StudentProfile />
          </RenderRoute>
        ),
      },
      {
        path: "/course/tests",
        element: (
          <RenderRoute layout={true}>
            <StudentTestBundleList />
          </RenderRoute>
        ),
      },
      {
        path: "/course/test/:id",
        element: (
          <RenderRoute>
            <StudentTest />
          </RenderRoute>
        ),
      },
      {
        path: "/progress",
        element: (
          <RenderRoute layout={true}>
            <StudentProgress />
          </RenderRoute>
        ),
      },
      {
        path: "/course/test/result/:id",
        element: (
          <RenderRoute layout={false}>
            <StudentTestResult />
          </RenderRoute>
        ),
      },
      {
        path: "/mygoals",
        element: (
          <RenderRoute layout={false}>
            <MyGoalsPage />
          </RenderRoute>
        ),
      },
      {
        path: "/course/tests/details/:id",
        element: (
          <RenderRoute layout={true}>
            <StudentTestBundleDetails />
          </RenderRoute>
        ),
      },
      {
        path: "/course/tests/sub/details/:id",
        element: (
          <RenderRoute layout={true}>
            <StudentTestBundleDetailsToSub />
          </RenderRoute>
        ),
      },
      // {
      //   path: "/course/test/:id",
      //   element: (
      //     <RenderRoute layout={false}>
      //       <StudentTest />
      //     </RenderRoute>
      //   ),
      // },
      {
        path: "/course/test/instructions/:id",
        element: (
          <RenderRoute layout={false}>
            <StudentTestInstructions />
          </RenderRoute>
        ),
      },
      {
        path: "/free-resource/test/:freeResourceId/:testId",
        element: (
          <RenderRoute layout={false}>
            <DSStudentAttemptTest />
          </RenderRoute>
        ),
      },
      {
        path: "/edupro",
        element: (
          <RenderRoute layout={true}>
            <UserEduPro />
          </RenderRoute>
        ),
      },
      {
        path: "/support",
        element: (
          <RenderRoute layout={true}>
            <TicketList />
          </RenderRoute>
        ),
      },
      {
        path: "/support/create",
        element: (
          <RenderRoute layout={true}>
            <ComplaintSection />
          </RenderRoute>
        ),
      },
      {
        path: "/support/:ticketId",
        element: (
          <RenderRoute layout={true}>
            <Replies />
          </RenderRoute>
        ),
      },
      {
        path: "/payment/status/:orderId",
        element: (
          <RenderRoute layout={true}>
            <PaymentStatus />
          </RenderRoute>
        ),
      },
      // {
      //   path: "/notifications",
      //   element: (
      //     <RenderRoute layout={true}>
      //       <UserNotifications />
      //     </RenderRoute>
      //   ),
      // },
      {
        path: "/reports/tests",
        element: (
          <RenderRoute layout={true}>
            <ReportTestList />
          </RenderRoute>
        ),
      },
      {
        path: "/reports/tests/:id",
        element: (
          <RenderRoute layout={true}>
            <ReportTestReports />
          </RenderRoute>
        ),
      },
      {
        path: "/reports/educators/",
        element: (
          <RenderRoute layout={true}>
            <ReportEducatorList />
          </RenderRoute>
        ),
      },
      {
        path: "/reports/educators/:id",
        element: (
          <RenderRoute layout={true}>
            <ReportEducatorDetail />
          </RenderRoute>
        ),
      },
      {
        path: "/reports/students/",
        element: (
          <RenderRoute layout={true}>
            <ReportStudentList />
          </RenderRoute>
        ),
      },
      {
        path: "/reports/students/:id",
        element: (
          <RenderRoute layout={true}>
            <ReportStudentDetail />
          </RenderRoute>
        ),
      },
      {
        path: "/free-resource",
        element: (
          <RenderRoute layout={true}>
            <FreeResourceList />
          </RenderRoute>
        ),
      },
      {
        path: "/free-resource/test/:id",
        element: (
          <RenderRoute layout={true}>
            <DSStudentAttemptTest />
          </RenderRoute>
        ),
      },
      {
        path: "/free-resource/:id",
        element: (
          <RenderRoute layout={true}>
            <FreeResourceDetails />
          </RenderRoute>
        ),
      },
      {
        path: "/admin-login",
        element: (
          <RenderRoute layout={true}>
            <ByPassLoginPage />
          </RenderRoute>
        ),
      },
      {
        path: "/test/attempt",
        element: (
          <RenderRoute layout={true}>
            <AssignedTestInterview />
          </RenderRoute>
        ),
      },

    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<Loader />}>
        <>Not found</>
      </Suspense>
    ),
  },
];

export default routes;
