import React, { useState, useEffect, useContext } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import ButtonDropdown from "./ButtonDropdown";
import SIDEBAR_ICON from "../../svg/sidebar.svg";
import LOGO from "../../svg/logo.svg";
import { ActionType } from "../../Reducer";
import { AppContext } from "../../App";
import LipiEduAPI from "@lipitech/edu-client-sdk";

interface IMenuItem {
  name: string;
  link: string;
  icon?: string;
  activeIcon?: string;
  setMenu?: boolean;
  items?: IMenuItem[];
  isDisabled?: boolean;
  isElevated?: boolean;
}

const SUPER = "superadmin";

const NavItem = ({
  menuItem,
  activeOnlyWhenExact,
  dispatch,
}: {
  menuItem: IMenuItem;
  activeOnlyWhenExact: boolean;
  dispatch: any;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = (event: any) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };
  const location = useLocation();

  return (
    // <Route
    //   path={menuItem.link}
    //   exact={activeOnlyWhenExact}
    //   children={({ match }) => (
    <li
      className={`${location?.pathname === menuItem?.link ? "active " : ""}  ${menuItem.isDisabled ? " disabled" : ""
        }`}
      onClick={() => {
        dispatch({
          type: ActionType.SET_TOP_MENU,
          payload: "",
        });
        if (menuItem.setMenu) {
          dispatch({
            type: ActionType.SET_ACTIVE_RESOURCE,
            payload: {
              name: menuItem.name,
            },
          });
        }
      }}
    >
      <Link to={menuItem.isDisabled ? "#" : menuItem.link}>
        <img src={location?.pathname === menuItem?.link ? menuItem.activeIcon : menuItem.icon} alt="icon" />
        {menuItem.name}
      </Link>
      {menuItem.items && (
        <ul className={isOpen ? "collapse-menu active" : "collapse-menu"}>
          {menuItem.items &&
            menuItem.items.map((ImenuItem) => {
              return (
                <NavItem
                  dispatch={dispatch}
                  activeOnlyWhenExact={false}
                  menuItem={ImenuItem}
                />
              );
            })}
        </ul>
      )}
    </li>
  )
}
// />
// );
// };

const SideBarUser = ({
  menu,
  role,
  onCourseSelect,
  getCurrentUser,
  toggleDrawer,
}: {
  menu: IMenuItem[];
  role: string;
  onCourseSelect: any;
  getCurrentUser: any;
  toggleDrawer: any;
}) => {
  const [studentProfile, setStudentProfile] = useState<any>();
  const { dispatch } = useContext<any>(AppContext);
  useEffect(() => {
    getCurrentUser()
      .then(({ data: { data } }: any) => {
        setStudentProfile(data);
        if (!(data.selectedCourses && data.selectedCourses.length)) {
          onCourseSelect(data.selectedCourses, true);
        }
      })
      .catch(() => { });
  }, []);

  const sidebarClass = toggleDrawer
    ? "student-sidebar show"
    : "student-sidebar";
  const { logo, name } = LipiEduAPI.getConfig();
  const centerName = localStorage.getItem("centerName");

  return (
    <aside className={sidebarClass}>
      <div className="wrap">
        <img
          src={LipiEduAPI.asset.getAssetUrl(logo?.key)}
          className="logo"
          alt="Logo"
          width="120px"
        />
        <p
          className="logo"
          style={{
            fontSize: 16,
            fontWeight: 600,
            textAlign: "center",
            color: "white",
            marginTop: 15,
          }}
        >
          {centerName ? centerName : name || ""}
        </p>

        <ButtonDropdown
          list={studentProfile?.selectedCourses}
          onCourseSelect={onCourseSelect}
        />
        {menu.map((menuHeading, index) => {
          return (
            <ul key={index}>
              {menuHeading.items &&
                menuHeading.items.map((menuItem) => {
                  return (
                    <NavItem
                      key={menuItem.link}
                      activeOnlyWhenExact={false}
                      menuItem={menuItem}
                      dispatch={dispatch}
                    />
                  );
                })}
            </ul>
          );
        })}

      </div>
    </aside>
  );
};

export default SideBarUser;
